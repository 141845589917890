import * as React from "react";
import { join, noop } from "lodash-es";
import { SGBSSize } from "../../common/common.typings";

export interface IconProps {
  iconName: string;
  className?: string;
  size?: SGBSSize;
  onClick?: () => void;
}

const generateIconClassName = (size?: SGBSSize, className?: string): string => {
  return join(['icon', size ? `icon-${size}` : '', className || ''], ' ').trim();
};

const Icon: React.FC<IconProps> = ({ iconName, size, className, onClick = noop }) => {
  return (
    <i className={generateIconClassName(size, className)} onClick={onClick}>
      {iconName}
    </i>
  );
};

export default Icon;
